<template>
  <div>
    <Header
      heading="Vermittlergruppe erstellen"
      :column-layout="true"
      :loading="loading"
    >
      <template #buttonSpace>
        <button
          :disabled="savePending"
          class="btn btn-success text-white"
          @click="save()"
        >
          Speichern
        </button>
      </template>
      <template #alert>
        <transition name="slide-fade">
          <Alert
            v-if="saveSuccesMessage"
            variant="success"
            :message="saveSuccesMessage"
            @close="saveSuccesMessage = null"
          />
        </transition>
        <transition name="slide-fade">
          <ca-alert
            v-if="showFormInvalidHint"
            variant="info"
            message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
            @close="showFormInvalidHint = null"
          />
        </transition>
        <transition name="slide-fade">
          <ca-alert
            v-if="errorMessage"
            variant="danger"
            :message="errorMessage"
            @close="errorMessage = null"
          />
        </transition>
      </template>
    </Header>
    <div class="container-fluid px-4 pt-4">
      <div class="row">
        <div class="col-12 col-xl-8 col-xxl-6">
          <Card>
            <template #header>
              <h4 class="mb-0">
                Gruppendaten
              </h4>
            </template>
            <template #body>
              <form
                v-if="!loading"
                novalidate
                @submit.prevent="save"
              >
                <BasicInput
                  v-model="agentGroup.number"
                  label="Vermittlergruppe Nr."
                  horizontal
                  :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                  required
                />
                <BasicInput
                  v-model="agentGroup.name"
                  label="Vorname (Suchname)"
                  horizontal
                  :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                  required
                />
                <BasicInput
                  v-model="agentGroup.name1"
                  label="Nachname (Name 1)"
                  horizontal
                  :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                />
              </form>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Layout/Header'
import Card from '@/components/Card.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import Alert from '@/components/Alert.vue'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Header,
    Card,
    BasicInput,
    Alert
  },
  data: () => ({
    loading: false,
    savePending: false,
    agentGroup: null,
    saveSuccesMessage: null,
    showFormInvalidHint: false,
    errorMessage: null
  }),
  created () {
    if (this.$route.params.agentGroupId) {
      this.fetchData()
    } else {
      this.agentGroup = new this.$FeathersVuex.api.AgentGroup()
    }
  },
  methods: {
    async fetchData () {
      this.loading = true
      try {
        this.agentGroup = await this.$store.dispatch('agent-groups/get', this.$route.params.agentGroupId)
        this.loading = false
      } catch (error) {
        this.errorMessage = error
      }
    },
    async save () {
      // validate
      if (this.savePending) return
      if (!(await this.$validator.validateAll())) {
        this.showFormInvalidHint = true
        return
      }
      // reset Feedbackstate
      this.showFormInvalidHint = false
      this.errorMessage = null
      this.saveSuccesMessage = null
      // save agentGroup
      this.savePending = true
      try {
        await this.agentGroup.save()
        this.saveSuccesMessage = 'Speichern erfolgreich'
      } catch (error) {
        console.error(error)
        this.errorMessage = error
      } finally {
        this.savePending = false
      }
    }
  }
}
</script>
